import { defineStore } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useCountryStore } from '@/stores/country'
import axios from '@/axios'

export const useUserStore = defineStore('user', (force = false) => {
  let isChecked = false

  const isAuthenticated = ref(false)
  const area = ref(-1)
  const contact = ref(null)
  const alertsEnabled = ref(false)
  const cmsEnabled = ref(false)
  const isGuest = computed(() => !isAuthenticated.value)

  const fetchUserData = async () => {
    const countryStore = useCountryStore()

    if (isChecked && !force) {
      return
    }

    try {
      const response = await axios.get('/api/auth/check')

      if (!response.data.authenticated) {
        isAuthenticated.value = false
        area.value = -1
        contact.value = null
        alertsEnabled.value = false
        cmsEnabled.value = false

        countryStore.setDefaultCountry()

        return
      }

      const currentUser = response.data.currentUser

      isAuthenticated.value = true
      area.value = currentUser.area
      contact.value = currentUser.contact
      alertsEnabled.value = currentUser.alertsEnabled
      cmsEnabled.value = currentUser.cmsEnabled

      countryStore.setDefaultCountry(currentUser.country.toLowerCase())
    } catch (e) {
      console.error(e)
    } finally {
      isChecked = true
    }
  }

  onMounted(() => {
    fetchUserData()
  })

  return {
    isAuthenticated,
    isGuest,
    area,
    contact,
    alertsEnabled,
    cmsEnabled,
    fetchUserData,
  }
})
